<template src="./Register06.pug" lang="pug"></template>
<script>

import { mapGetters } from 'vuex'

  export default {
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      aceite: false
    }),
    computed: {
      ...mapGetters(['selectedInstallments', 'selectedInstallmentsFactor', 'selectedInstallmentsFee', 'selectedAmount', 'cardLimitLeft'])
    },
    methods: {
      formatPrice(value) {
        if(value == undefined){ value = 0 }
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
    }
  }
</script>
