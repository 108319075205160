<template src="./Register05.pug" lang="pug"></template>
<script>

import Vue from 'vue'
import filter from 'lodash/filter'
Vue.prototype.filter = filter

import {VMoney} from 'v-money'

import * as types from '@/store/mutation-types'
import { store } from '@/store'

import { mapGetters, mapActions } from 'vuex'

  export default {
    directives: {money: VMoney},
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      showMoreOptions: false,
      selectedAmount: 0,
      selectedInstallments: 0,
      selectedInstallmentsFactor: 0,
      selectedInstallmentsFee: 0,
      showError: false,
      ammount: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      },
      valid: true,
    }),

    computed: {
      ...mapGetters(['installments', 'cardLimitLeft', 'cpf', 'cardLimitTotal', 'need']),
    },

    methods: {
      ...mapActions(['postCpf']),

      formatPrice(value) {
        if(value == undefined){ value = 0 }
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },

      validateVal(value){

        const max = this.cardLimitLeft
        const min = 200

        let price = value
        if( typeof(value) === 'string' ){
          price = Number( (value.trim().replace(/[^0-9]/g, '')/100).toFixed(2) )
        }

        if( price < min ) return "O mínimo permitido é "+(min).toLocaleString("pt-BR", {style: "currency", currency: "BRL", minimumFractionDigits: 2})
        if( price > max ) return "O limite disponível no cartão é "+(max).toLocaleString("pt-BR", {style: "currency", currency: "BRL", minimumFractionDigits: 2})
        return true
      },

      async changeAmmount() {
        if(this.$refs.form.validate()) {
          const user = JSON.parse(localStorage.getItem('user'))
          await this.postCpf({
            cpf: this.cpf,
            user: user.id,
            cardLimitTotal: this.cardLimitTotal,
            cardLimitLeft: Number( (this.ammount.trim().replace(/[^0-9]/g, '')/100).toFixed(2) ),
            reason: this.need
          })
        }
      },

      setInstallments(i, status) {
        if(status == 'hidden') {
          i = i+3
        }

        this.selectedAmount = this.installments[i].avaiable
        this.selectedInstallments = parseInt(this.installments[i].n)
        this.selectedInstallmentsFactor = this.installments[i].f
        this.selectedInstallmentsFee = this.installments[i].i

      },

      goNext() {
        if(this.selectedInstallments != 0) {
          store.commit(types.SELECTED_AMOUNT, this.selectedAmount)
          store.commit(types.SELECTED_INSTALLMENTS, this.selectedInstallments)
          store.commit(types.SELECTED_INSTALLMENTS_FACTOR, this.selectedInstallmentsFactor)
          store.commit(types.SELECTED_INSTALLMENTS_FEE, this.selectedInstallmentsFee)
          this.$router.push('register06')
        }else {
          this.showError = true
        }
      }

    },
    mounted() { this.ammount = this.cardLimitLeft
      document.querySelector("input[name=priceVal]").value = this.ammount*100
    }
  }
</script>
