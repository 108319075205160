<template src="./Register08.pug" lang="pug"></template>
<script>

import {mask} from 'vue-the-mask'
import {VMoney} from 'v-money'

import * as types from '@/store/mutation-types'
import { store } from '@/store'

  export default {
    directives: {mask, money: VMoney},
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      aceite: false,

      monthlyIncome: 0,
      profession: '',
      education: '',

      valid: true,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      sector: ['1', '2'],
      schooling: ['1º grau', '2º grau completo', '3º grau completo', 'Pós graduação', 'Mestrado', 'Doutorado'],
      rules: {
        required: value => !!value || 'Campo obrigatório',
      },
    }),
    methods: {

      goNext() {

        if(this.$refs.form.validate()) {
          store.commit(types.MONTHLY_INCOME, Number( this.monthlyIncome.trim().replace(/[^0-9]/g, '')/100 ))
          store.commit(types.PROFESSION, this.profession)
          store.commit(types.EDUCATION, this.education)

          this.$router.push('register09')
        }
      }

    },
    computed: {
      professions() {
        return require('../common/professions.js')
      }
    }
  }
</script>
