<template src="./Register11.pug" lang="pug"></template>
<script>

import {mask} from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
// import * as types from '@/store/mutation-types'
// import { store } from '@/store'

import pagarme from 'pagarme'

  export default {
    directives: {mask},
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      aceite: false,
      valid: true,
      cardNumber: '',
      cardName: '',
      cardDate: '',
      cardCvv: '',

      cardBrandIcon: 'card_default.png',
      cardBrandSecurityCode: 'CVV',
      cardBrandSecuritySize: 4,

      rules: {
        required: value => !!value || 'Campo obrigatório',
        validade: value => (value && value.length == 5) || 'Informe a data completa',
      },
    }),
    computed: {
      ...mapGetters(['cardHash', 'userId', 'cardLimitTotal', 'cardLimitLeft', 'need', 'installments', 'selectedInstallments', 'selectedInstallmentsFactor', 'selectedInstallmentsFee', 'selectedAmount', 'name', 'email', 'birthday', 'motherName', 'cellphone', 'gender', 'monthlyIncome', 'profession', 'education', 'cep', 'state', 'street', 'neighborhood', 'city', 'number', 'complement', 'accountType', 'bank', 'bankName', 'agency', 'account', 'accountDigit', 'pixKeyType', 'pixKey'])
    },
    methods: {
      ...mapActions(['generateHash', 'updateData']),

      async registerUser(){

        // const total = Math.floor(this.selectedAmount*this.selectedInstallmentsFactor*this.selectedInstallments)
        // const amount = parseInt(((total)*100).toFixed(0))
        const amount = this.selectedInstallments * parseFloat((Math.floor((this.selectedAmount*this.selectedInstallmentsFactor) * 100) / 100).toFixed(2))

        let payload = {
          _id: this.userId,

          amount: (amount*100).toFixed(0),
          installments: this.selectedInstallments,

          cardLimitTotal: this.cardLimitTotal,
          cardLimitLeft: this.cardLimitLeft,
          need: this.need,

          selectedInstallmentsFactor: this.selectedInstallmentsFactor,
          selectedInstallmentsFee: this.selectedInstallmentsFee,

          name: this.name,
          email: this.email,
          birthday: this.birthday,
          motherName: this.motherName,
          cellphone: this.cellphone,
          gender: this.gender,
          monthlyIncome: this.monthlyIncome,
          occupation: this.profession,
          education: this.education,
          bankData: {},
          address: {
            zipCode: this.cep,
            state: this.state,
            streetName: this.street,
            neighborhood: this.neighborhood,
            city: this.city,
            streetNumber: this.number
          }
        }

        if(this.complement != null) { payload.address.additionalAddress = this.complement }


        if(this.accountType == 'pix') {
          payload.bankData.pix = {}
          payload.bankData.pix.keyType = this.pixKeyType
          payload.bankData.pix.key = this.pixKey
        }else {

          payload.bankData.accountType = this.accountType,
          payload.bankData.bankNumber = this.bank,
          payload.bankData.bankName = this.bankName,
          payload.bankData.agency = this.agency,
          payload.bankData.account = this.account
          if(this.accountDigit != null) { payload.bankData.accountDigit = this.accountDigit }
        }

        await this.updateData(payload)
        this.$router.push('register12')
      },

      async getHash() {

        console.log(this.cardNumber)

        var hashPayload = {
          card_number: this.cardNumber.match(/\d/g).join(""),
          card_holder_name: this.cardName,
          card_expiration_date: this.cardDate.match(/\d/g).join(""),
          card_cvv: this.cardCvv,
        }

        await this.generateHash(hashPayload)

        this.registerUser()
      },

      cardBrand(){

        if(this.cardNumber.length >= 4){
          var creditCardType = require("credit-card-type")
          var visaCards = creditCardType(this.cardNumber.substring(0,4))
          if(visaCards[0]){
            switch (visaCards[0].type) {
              case 'visa':
                this.cardBrandIcon = 'card_visa.png'
                break;
              case 'mastercard':
                this.cardBrandIcon = 'card_mastercard.png'
                break;
              case 'american-express':
                this.cardBrandIcon = 'card_amex.png'
                break;
              case 'diners-club':
                this.cardBrandIcon = 'card_diners.png'
                break;
              case 'discover':
                this.cardBrandIcon = 'card_discover.png'
                break;
              case 'jcb':
                this.cardBrandIcon = 'card_jcb.png'
                break;
              case 'unionpay':
                this.cardBrandIcon = 'card_unionpay.png'
                break;
              case 'maestro':
                this.cardBrandIcon = 'card_maestro.png'
                break;
              case 'elo':
                this.cardBrandIcon = 'card_elo.png'
                break;
              case 'hipercard':
                this.cardBrandIcon = 'card_hipercard.png'
                break;

              default:
                this.cardBrandIcon = 'card_default.png'

            }
            this.cardBrandSecurityCode = visaCards[0].code.name
            this.cardBrandSecuritySize = visaCards[0].code.size

          }else{
            this.cardBrandIcon = 'card_default.png'
          }
        }
      },

      validCreditCard() {
        return new Promise((resolve) => {
          var card = {}
          card.card_holder_name = this.cardName
          card.card_expiration_date = this.cardDate
          card.card_number = this.cardNumber
          card.card_cvv = this.cardCvv

          // pega os erros de validação nos campos do form e a bandeira do cartão
          var cardValidations = pagarme.validate({card: card})

          //Então você pode verificar se algum campo não é válido
          if(!cardValidations.card.card_number){
            this.cardNumber = ''
          }
          if(!cardValidations.card.card_holder_name){
            this.cardName = ''
          }
          if(!cardValidations.card.card_expiration_date){
            this.cardDate = ''
          }
          if(!cardValidations.card.card_cvv){
            this.cardCvv = ''
          }
          resolve(true)
        })
      },

      async goNext() {
        if(this.$refs.form.validate()) {
          await this.validCreditCard()
          this.getHash()
        }
      }

    }
  }
</script>
