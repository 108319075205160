<template lang="pug">
  v-container(fluid class="bg-terms")
    h1(class="primary--text") Termos de uso

    p(class="accent--text") Estes Termos de Uso (ou simplesmente “Termo” ou “Termos de Uso”) regulam a forma de funcionamento e condições aplicáveis ao uso do site www.iFraldas.com.br, de titularidade da IFRALDAS SERVIÇOS LTDA., com sede na Av. Presidente Itamar Franco, 3.180, Apto 2003, São Mateus, Juiz de Fora - MG, CEP: 36.025-290, inscrita sob CNPJ 30.304.899/0001-05 (“iFraldas”), doravante denominado como “Plataforma”.

    p(class="accent--text") Além deste Termo, é necessário que seja efetuada a sua leitura cuidadosa da nossa Política de Privacidade, a qual estabelece as condições de coleta e processamento dos seus dados, inclusive pessoais, durante sua navegação na Plataforma.

    p(class="accent--text") Ao clicar no botão de aceitação destes Termos de Uso e/ou ao navegar na Plataforma, você, na qualidade de pessoa física (doravante denominado “Você” ou “Usuário”) aceita e concorda com estes Termos de Uso e com a Política de Privacidade da Plataforma, conforme as informações abaixo. Caso não concorde com as disposições deste Termo ou da Política de Privacidade, recomenda-se que não navegue na Plataforma.

    p(class="accent--text") A iFraldas poderá modificar estes Termos de Uso, a qualquer momento, a seu exclusivo critério, mediante a publicação de Termos de Uso atualizados. Para a sua comodidade, a data da última revisão é incluída no final desta página. Recomendamos que toda vez que você acessar a Plataforma, fique atento às novas atualizações, pois o seu acesso e uso da Plataforma estarão vinculados por quaisquer alterações destes Termos de Uso.


    h2(class="accent--text") 1. Definições do Termos de Uso

    p(class="accent--text") 1.1. “Usuários” são as pessoas físicas que simplesmente navegam na Plataforma sem realizar o procedimento de solicitação de empréstimo ou que tenham realizado com sucesso o procedimento de solicitação de empréstimo.


    p(class="accent--text") 1.2. “Parceiro” são instituições financeiras legalmente autorizadas pelos órgãos competentes a realizar empréstimos.


    p(class="accent--text") 1.3.  “Equipamentos” são o computador, smartphone, tablet ou qualquer outro hardware, de responsabilidade exclusiva do Usuário, que se compromete a manter em ambiente seguro, com o uso de antivírus e ﬁrewall, entre outras, atualizadas, de modo a contribuir com a cibersegurança.


    p(class="accent--text") 1.4. “Plataforma” se refere à plataforma on-line para intermediação das negociações de empréstimos entre o Usuário e os Parceiros estabelecida no site www.ifraldas.com.br.



    h2(class="accent--text") 2. Objetivo da iFraldas e dos Serviços

    p(class="accent--text") 2.1. A iFraldas atua como correspondente bancária e nossos serviços têm por objetivo a intermediação das negociações de empréstimo de valores entre o Usuário e os Parceiros através de uma plataforma on-line que pode ser acessada pelo site www.ifraldas.com.br.


    p(class="accent--text") 3.2. A iFraldas também efetua a cobrança dos empréstimos por ela intermediados por meio de cartões de crédito ou outros meios de pagamento.


    p(class="accent--text") 3.3. A iFraldas não é uma instituição financeira, mas somente uma intermediadora de empréstimo e cobrança entre Usuários e os Parceiros.


    p(class="accent--text") 3.4. A iFraldas pode realizar aquisições de títulos de recebíveis de empréstimos por ela intermediados.


    h2(class="accent--text") 3. Habilitação para Utilização dos Serviços da iFraldas

    p(class="accent--text") 3.1. Para a devida utilização dos serviços oferecidos pela iFraldas, o Usuário:
    p(class="accent--text") Declara e garante, sob as penas da lei, ser maior de 18 anos e legalmente capaz;
    p(class="accent--text") Ser uma pessoa física com capacidade civil e estar em plena atividade e gozo de seus direitos civis;
    p(class="accent--text") Ser legítimo titular do cartão de crédito emitido por instituição financeira estabelecida em território Brasileiro;
    p(class="accent--text") Ser legítimo titular de contas bancárias devidamente ativas em instituição financeira estabelecida em território Brasileiro para disponibilização do empréstimo.
    p(class="accent--text") Ser o legítimo possuidor dos documentos de identificação com foto recente, válido em território nacional, que contenha número do CPF.
    p(class="accent--text") 3.2. Não violará nenhum direito da iFraldas ou de terceiros, incluindo direitos de propriedade intelectual, tais como direitos autorais ou direitos sobre marcas;
    p(class="accent--text") 3.3. Fornecerá, às suas custas, todos os equipamentos, software, acesso a celular e acesso à Internet necessários para utilizar a Plataforma;
    p(class="accent--text") 3.4. As informações fornecidas durante o Cadastro são exatas, precisas e verdadeiras, e serão mantidas atualizadas pelo próprio Usuário, estando sujeitas à verificação e aprovação da iFraldas, a seu exclusivo critério.
    p(class="accent--text") 3.5. Ao utilizar os serviços da iFraldas, o Usuário reconhece expressamente que a iFraldas não terá qualquer obrigação ou responsabilidade no caso de violação das condições observadas no Termos de Uso.
    p(class="accent--text") 3.6. A iFraldas não poderá ser responsável pelas atividades realizadas e pelas informações fornecidas pelos Usuários, inclusive com relação às informações pessoais, cartões de crédito apresentados, documentos pessoais de identidade, etc., se responsabilizando somente pelos serviços efetivamente contratados, quais sejam, de intermediação e captura dos valores contratados no cartão de crédito do Usuário para fins de concretização do negócio entre os Usuários e Parceiros.

    h2(class="accent--text") 4. Utilização dos Serviços da iFraldas

    p(class="accent--text") 4.1. Para aproveitamento de todas as funcionalidades e serviços oferecidos pela iFraldas, o Usuário deverá realizar as seguintes etapas abaixo.
    p(class="accent--text") 4.2. Ao acessar a Plataforma, o Usuário deverá preencher um formulário no qual indicará o nome, e-mail, celular, valor que deseja emprestado e o prazo para o pagamento do empréstimo, cujo valor e parcelas poderão ser limitadas pela iFraldas, a seu exclusivo critério.
    p(class="accent--text") 4.3. Serão previamente informadas ao Usuário o resumo do empréstimo, que indicará o valor do empréstimo, o valor das parcelas, o prazo para pagamento, os juros, Custo Efetivo Total (CET) e os impostos relacionados.
    p(class="accent--text") 4.4. Usuário pessoa física deverá fornecer suas informações pessoais cadastrais para análise de crédito, assim considerados:<br />
    | Nome completo;<br />
    | Endereço eletrônico;<br />
    | Endereço físico completo;<br />
    | Celular de contato;<br />
    | Número do CPF;<br />
    | Dados do cartão de crédito de mesma titularidade do usuário;<br />
    | Dados bancários de mesma titularidade do usuário.
    p(class="accent--text") 4.5. O usuário dará permissão para receber e armazenar seus dados pessoais e biométricos a fim de promover maior segurança no uso de sua identidade e prevenir o uso indevido dos seus dados.
    p(class="accent--text") Ao concordar com esta cláusula/termo, o usuário autoriza a coletar/receber e armazenar os seus dados pessoais e biométricos (“Dados”) solicitados no momento desta operação e/ou anteriores que eventualmente já tenham ocorrido, para o processamento bem como declara ainda que tem ciência e concorda que os seus dados serão utilizados nesta e em futuras operações de crédito ou que importem risco financeiro da iFraldas, de modo que o uso dos seus dados permanecerão armazenados e controlados para cumprimento das seguintes finalidades (i) geração de score de autenticação biométrica, sendo que o referido score poderá ser gerado por meio de comparações dos seus dados informados e a base de dados mantida pela iFraldas, periodicidade de consumo, bem como por meio de eventuais consultas às informações públicas e/ou análise de documentos pessoais, quando aplicável; (ii) promover maior segurança no uso da sua identidade de forma a prevenir o uso indevido dos seus dados; (iii) servir de prova legal em âmbito judicial; (iv) cumprir ordem judicial ou de autoridade administrativa; (v) desenvolver, manter e aperfeiçoar os recursos e funcionalidades do produto de biometria facial. O usuário declara ainda que caso não se sinta confortável em manter seus dados com a iFraldas, poderá solicitar a exclusão dos seus dados a qual deverá comunicar o pedido para exclusão destes dados formalizando email para contato@ifraldas.com.br.
    p(class="accent--text") 4.6. A iFraldas emitirá a Cédula de Crédito Bancária “CCB” que estabelece os termos da contratação do empréstimo entre o Usuário e o Parceiro e coletará a assinatura digital.
    p(class="accent--text") 4.7. Ao ser completamente preenchido o Cadastro pelo Usuário, será efetivada a transação do valor do empréstimo contratado no cartão de crédito do Usuário.
    p(class="accent--text") 4.8. Caso não seja autorizada a transação, será exibida uma mensagem para que o Usuário entre em contato com o seu banco.
    p(class="accent--text") 4.9. A verificação dos dados e documentos enviados pelo Usuário é analisada em até 1 dia útil e em casos de finais de semana ou feriados o usuário no próximo dia útil.
    p(class="accent--text") 4.10. Estando os dados e documentos fornecidos pelo Usuário em conformidade e o empréstimo aprovado pelo Parceiro, o valor do empréstimo contratado será disponibilizado, pelo Parceiro, na conta bancária informada pelo Usuário em até 1 (um) dia útil.


    h2(class="accent--text") 5. Informações Adicionais
    p(class="accent--text") 5.1. A iFraldas reputa como precisas e verdadeiras as informações fornecidas pelos usuários. De qualquer forma poderá, a seu exclusivo critério, solicitar o envio de informações complementares caso necessário.
    p(class="accent--text") 5.2. A iFraldas não cobra quaisquer valores, taxas, comissões, adiantamentos ou afins, a qualquer título ou tempo, diretamente dos Usuários.
    p(class="accent--text") 5.3. Importante ressaltar que a iFraldas é apenas um agente intermediador de negócios, atuando como Correspondente Bancário, não sendo responsável perante o usuário e/ou a instituição financeira parceira, tampouco pelos empréstimos contratados ou suas características, tais como valor, data de vencimento das parcelas, juros, taxas, etc.
    p(class="accent--text") 5.4. A intermediação da iFraldas resume-se na análise de disponibilidade de limite no cartão de crédito que é informado pelo próprio usuário, da transmissão das informações que foram prestadas pelo usuário na plataforma para o parceiro com o qual é contratado e o empréstimo e pela captura dos valores contratados no cartão de crédito do Usuário para fins de cobrança do negócio, facilitando e agilizando todo processo de empréstimo.
    p(class="accent--text") 5.5. Sendo assim, o Usuário reconhece e concorda que utiliza a iFraldas por sua conta e risco, visto que esta não possui qualquer interferência ou ingerência face à relação entre o usuário e as Instituições financeiras parceiras.
    p(class="accent--text") 5.6. A iFraldas não é responsável pelas decisões financeiras que você possa vir a fazer por meio da Plataforma, e tampouco é parte no contrato/relação entre usuários e Parceiros, não podendo ser responsabilizada pelas obrigações ali assumidas.
    p(class="accent--text") 5.7. A iFraldas poderá, a qualquer tempo, adquirir a CCB eventualmente gerada na operação, sendo esta aquisição, autônoma e independente, uma relação direta com o Parceiro.

    h2(class="accent--text") 6. Aos Visitantes e aos Usuários, é vedado:

    p(class="accent--text") 6.1. Violar qualquer legislação municipal, nacional ou internacional que seja integrada ao ordenamento jurídico brasileiro, ou ainda, que, por qualquer razão legal, deva ser no Brasil aplicada;
    p(class="accent--text") 6.2. Praticar atos contrários à moral e aos bons costumes;
    p(class="accent--text") 6.3. Assumir a personalidade ou identidade de outra pessoa, física ou jurídica;
    p(class="accent--text") 6.4. Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer outra forma tornar disponível qualquer conteúdo que seja ilegal, incluindo, mas sem se limitar a, conteúdo que seja ofensivo à honra e à privacidade de terceiros, pornográfico, obsceno, difamatório ou calunioso, vulgar, preconceituoso, racista, discriminatório, que faça apologia ao crime ou de qualquer forma censurável, ou que possa gerar qualquer responsabilidade civil ou criminal de acordo com a Lei;
    p(class="accent--text") 6.5. Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer conteúdo que viole quaisquer direitos de terceiro, incluindo Direitos de Propriedade Intelectual (conforme definido abaixo);
    p(class="accent--text") 6.6. Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer tipo de anúncio, propaganda ou material promocional não solicitado ou não autorizado pelo Site, tais como mensagens não solicitadas ou mensagens enviadas em massa (conhecidos como “spam”);
    p(class="accent--text") 6.7. Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer conteúdo que contenha vírus ou qualquer outro código, arquivo ou programa de computador com o propósito de interromper, destruir ou limitar a funcionalidade de qualquer software, hardware ou equipamento;
    p(class="accent--text") 6.8. Ameaçar, coagir, constranger demais usuários;
    p(class="accent--text") 6.9. Violar direitos de sigilo e privacidade de terceiros;
    p(class="accent--text") 6.10. Praticar quaisquer atos que direta ou indiretamente, no todo ou em parte, possam causar prejuízo à iFraldas, a outros usuários ou a qualquer terceiro;
    p(class="accent--text") 6.11. Utilizar a iFraldas para outros fins comerciais não expressamente previstos neste Termos de Uso;


    h2(class="accent--text") 7. Notificações

    p(class="accent--text") 7.1. Para o pleno aproveitamento da Plataforma e dos serviços, a iFraldas poderá lhe enviar e-mails, mensagens via WhatsApp e SMS e notificações sobre atividades, anúncios, informações promocionais, campanhas de marketing em geral, avisos relacionados a temas de interesse e outros comunicados, sejam eles próprios ou de terceiros. Desta forma, você expressamente concorda em receber comunicações por meio de sua conta da iFraldas ou por outros meios, inclusive por e-mail, telefone celular, telefone ou serviços de entrega, inclusive sobre atualizações e informações de seus contatos, parceiros, anunciantes, ofertas ou qualquer outra atividade que consideramos pertinentes a você.
    p(class="accent--text") 7.2. Você reconhece e aceita que nós não teremos nenhuma responsabilidade associada ou decorrente da falta de precisão de suas informações de contato ou outras informações, incluindo, entre outros casos, se você deixar de receber informações essenciais sobre a iFraldas.
    p(class="accent--text") 7.3. O usuário desde já declara e reconhece ser o único responsável pelo armazenamento dos e-mails, mensagens e notificações enviados pela iFraldas, inclusive aqueles que contenham entregue e os documentos relacionados, não cabendo à iFraldas nenhuma responsabilidade neste sentido.


    h2(class="accent--text") 8. Propriedade Intelectual

    p(class="accent--text") 8.1. Dos direitos da iFraldas
    p(class="accent--text") 8.1.1. Este Site contém nome de domínio, textos, fotograﬁas, imagens, vídeos, marcas, logomarcas, layouts, código-fonte, gráficos, know-how, som entre outros elementos e materiais que se encontram protegidos por direitos de propriedade industrial e direitos autorais (“Direitos de Propriedade Intelectual”). Todos os Direitos de Propriedade Intelectual aqui presentes pertencem à iFraldas ou foram outorgados à iFraldas, de tal forma que apenas a iFraldas possui o direito de usar, explorar, reproduzir, comercializar ou licenciar a terceiros.
    p(class="accent--text") 8.1.2. Ao acessar a página da iFraldas o usuário declara que irá respeitar os Direitos de Propriedade Intelectual da iFraldas, bem como de todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma disponíveis no Site.
    p(class="accent--text") 8.2. Reprodução de conteúdo para uso próprio
    p(class="accent--text") 8.2.1. A reprodução de quaisquer conteúdos do Site é proibida, salvo prévia autorização por escrito da iFraldas, ou nos casos em que se destina ao uso exclusivamente pessoal do usuário. Em nenhum dos casos o usuário adquirirá qualquer Direito de Propriedade Intelectual sobre referidos conteúdos.
    p(class="accent--text") 8.2.2. Em caso de violação pelo usuário de qualquer Direito de Propriedade Intelectual da iFraldas, o usuário assume toda e qualquer responsabilidade, de caráter civil e/ou criminal advindos de referida violação.
    p(class="accent--text") 8.3. Aviso por infração de direitos autorais
    p(class="accent--text") 8.3.1. A iFraldas acredita na eficácia do sistema de proteção à propriedade intelectual e é uma empresa que se preocupa em respeitar a propriedade intelectual alheia. Se, por qualquer motivo, você entender que algum direito seu está sendo infringido pelos textos, artigos, vídeos, notícias, fotograﬁas, layouts ou qualquer outra obra reproduzida em nosso Site, pedimos que nos envie uma mensagem detalhada, para contato@ifraldas.com.br, a ﬁm de que possamos responder-lhe diretamente e resolvermos a situação. A mensagem deverá conter: a) assinatura eletrônica ou física da pessoa autorizada a agir em nome do proprietário dos direitos; (b) descrição da marca ou obra protegida por direitos autorais que alega ter sido infringida; (c) o endereço virtual (URL) em que se encontra o material que alega apresentar infração ao seu direito; (d) seu endereço, número de telefone e e-mail para contato; (e) declaração de que você tem boas razões para acreditar que o uso contestado não é autorizado pelo proprietário dos direitos ou pela lei; e (f) declaração feita por você de que as informações em sua notificação são precisas e de que você é o proprietário dos direitos ou autorizado a atuar em nome do proprietário dos direitos.
    p(class="accent--text") 8.3.2. A eventual retirada de qualquer conteúdo de nosso Site em decorrência de alguma reclamação, deverá ser sempre compreendida como uma demonstração de nossa intenção de evitar pendências com relação a esse assunto e, jamais, como reconhecimento de qualquer infração a direito de terceiro.
    p(class="accent--text") 8.4. Envio de informações não solicitadas
    p(class="accent--text") Para a segurança do usuário, é política da iFraldas não aceitar ou considerar ideias, sugestões ou materiais criativos, incluindo, entre outros, anotações, desenhos, conceitos, comentários, sugestões de técnicas, informações sobre know-how, material publicitário, de vídeo ou outras informações (“envios”) diferentes daqueles especificamente solicitados para o cadastro do usuário na Plataforma. A intenção dessa política é evitar a possibilidade de futuros mal-entendidos quando projetos desenvolvidos pelos profissionais da iFraldas forem semelhantes aos trabalhos criativos de terceiros que tenham realizado qualquer envio à iFraldas.


    h2(class="accent--text") 9. Responsabilidades

    p(class="accent--text") 9.1. O usuário reconhece expressamente que a iFraldas não é responsável por nenhum problema técnico e/ou operacional, incluindo falhas, perda de informação, suspensões, interrupções e qualquer outro mal desempenho da plataforma e dos serviços, nem oferece nem nunca ofereceu qualquer tipo de garantia aos usuários ou a qualquer terceiro a respeito da utilização da plataforma e dos serviços.
    p(class="accent--text") 9.2. A iFraldas não poderá ser responsabilizada por vícios ou defeitos técnicos e/ou operacionais oriundos da Plataforma, dos serviços, de informações do usuário ou de terceiros.
    p(class="accent--text") 9.3. A iFraldas se exime de qualquer responsabilidade pelos danos e consequentes prejuízos de qualquer natureza decorrentes da presença de vírus ou outro código, arquivo ou programa recebido.
    p(class="accent--text") 9.4. A utilização da plataforma e dos serviços é feita sob inteira responsabilidade do Usuário. Em geral, os serviços dependem da funcionalidade de vários fatores, como o fornecimento de informações precisas e verdadeiras pelos usuários, a interação de servidores e serviços de telecomunicações de terceiros, a adequação dos equipamentos do usuário e de terceiros, entre outros.
    p(class="accent--text") 9.5. Tendo isso em consideração, a iFraldas empregará seus melhores esforços para assegurar que a plataforma funcione adequadamente, sem interferências.
    p(class="accent--text") 9.6. No entanto, a iFraldas não garante que estará disponível de forma ininterrupta ou livre de quaisquer erros ou falhas e tampouco assegura a impossibilidade do seu sistema e servidores poderem ser atacados, invadidos, modificados ou lesados, de qualquer forma, por terceiros.
    p(class="accent--text") 9.7. A iFraldas não é responsável:<br />
    | a) pela qualidade da conexão do dispositivo de acesso (computador, smartphone, tablet, etc.) do usuário à internet em qualquer circunstância;<br />
    | b) pela disponibilidade ou não dos serviços de acesso à internet prestados por terceiros;<br />
    | c) pela impossibilidade de utilização de quaisquer dos serviços acima mencionados em razão da incompatibilidade dos serviços com a configuração técnica do dispositivo de acesso dos usuários;<br />
    | d) por quaisquer danos sofridos pelo usuário em razão da utilização da iFraldas, e<br />
    | e) pela reparação de danos de qualquer natureza causados pela quebra de privacidade ou segurança de dados do usuário durante a conexão de seu dispositivo de acesso à internet através de terceiros.
    p(class="accent--text") 9.8. O Usuário que infringir os presentes Termos de Uso será responsabilizado pelos danos e prejuízos de qualquer natureza que a iFraldas venha a sofrer ou aqueles causados a terceiros.


    h2(class="accent--text") 10. Indenização

    p(class="accent--text") 10.1. Os Usuários concordam em isentar e indenizar, defender e manter a iFraldas e seus respectivos diretores, agentes, sócios e funcionários indenes de qualquer prejuízo, responsabilidade, ação judicial ou demanda, incluindo honorários advocatícios, devidos a ou decorrentes do uso do Site ou de violação destes Termos de Uso ou da Política de Privacidade.
    p(class="accent--text") 10.2. Os Usuários concordam, ainda, em isentar e indenizar, defender e manter a iFraldas e seus respectivos diretores, agentes, sócios e funcionários indenes de qualquer prejuízo, responsabilidade, ação judicial ou demanda, incluindo honorários advocatícios, devidos a ou decorrentes de falhas ocorridas nos equipamentos do usuário, assim como da utilização indevida das informações ou materiais do Site.


    h2(class="accent--text") 11. Prazo

    p(class="accent--text") 11.1. Estes Termos permanecerão em pleno vigor e efeito enquanto: (a) os serviços forem disponibilizados na Plataforma e não forem substituídos por outro “termos de uso”; (b) enquanto o usuário estiver acessando a Plataforma; e (c) enquanto o usuário estiver cadastrado na Plataforma.
    p(class="accent--text") 11.2. Se você é um usuário cadastrado, você pode solicitar a remoção de sua conta e encerrar a sua participação a qualquer momento, independentemente do motivo, enviando a solicitação pelo e-mail contato@ifraldas.com.br.
    p(class="accent--text") 11.3. A iFraldas poderá, a seu exclusivo critério, cancelar qualquer conta e remover qualquer serviço ou conteúdo disponibilizado no Site, a qualquer momento e por qualquer motivo, sem que isso gere ao usuário qualquer direito de indenização.


    h2(class="accent--text") 12. Disposições gerais

    p(class="accent--text") 12.1. Se qualquer disposição destes Termos de Uso tornar-se inválida ou inexequível, tal disposição será anulada e as demais disposições serão mantidas.
    p(class="accent--text") 12.2. Os títulos são apenas para efeitos de referência e de forma alguma definem, limitam, determinam a interpretação ou descrevem o âmbito ou extensão da respectiva seção.
    p(class="accent--text") 12.3. A omissão da iFraldas com relação a qualquer falha do usuário ou de outros em cumprir com estes Termos de Uso não significa renúncia dos direitos da iFraldas de agir em relação às falhas subsequentes ou similares.
    p(class="accent--text") 12.3. O Usuário não poderá ceder ou transferir a qualquer terceiro, total ou parcialmente, seus direitos e obrigações decorrentes destes Termos de Uso.


    h2(class="accent--text") 13. Lei aplicável e jurisdição

    p(class="accent--text") Os presentes Termos de Uso serão interpretados de acordo com as leis da República Federativa do Brasil. Fica eleito o Foro da Comarca d Juiz de Fora, estado de Minas Gerais para dirimir qualquer divergência oriunda dos presentes Termos de Uso, com renúncia expressa a qualquer outro, por mais privilegiado que seja.

    h2(class="accent--text") 14. Dúvidas

    p(class="accent--text") Caso tenha qualquer dúvida em relação aos presentes Termos de Uso, favor entrar em contato com a iFraldas, por meio do e-mail contato@ifraldas.com.br.
    p(class="accent--text") Última atualização: 22/06/2022
</template>
<script>
  export default {
    data: () => ({

    })
  }
</script>
