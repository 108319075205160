<template src="./Register01.pug" lang="pug"></template>
<script>

  export default {
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      show: false
    })
  }
</script>
