<template lang="pug">
  v-container(fluid class="main-container")
    v-row(justify="center")
      v-col(cols="12" sm="12" md="10" lg="10" xl="10")

        v-card(color="white")
          v-row(align="center")

            v-col(cols="12" sm="12" md="5" lg="5" xl="5")
              v-row(justify="center")
                v-col(cols="12" sm="12" md="11" lg="11" xl="11")
                  div
                    v-img(src="https://cdn.ifraldas.net/site/img-index.png" lazy-src="https://cdn.ifraldas.net/site/img-index.png" aspect-ratio="1")

            v-col(cols="12" sm="12" md="7" lg="7" xl="7")
              v-row
                v-col
                  p(class="display-1 primary--text ml-4") Transforme seu cartão de crédito em dinheiro!
              v-row
                v-col
                  p(class="title accent--text ml-4 font-weight-regular") Use o limite do seu cartão e resgate em dinheiro ainda HOJE
              v-row(justify="center")
                v-col(cols="10" sm="10" md="6" lg="6" xl="6")
                  v-card(elevation="0")
                    v-card-text
                      v-form(ref="form" lazy-validation v-model="valid")
                        v-text-field(v-model="cpf" label="CPF" placeholder="000.000.000-00" :rules="validCPF" v-mask="'###.###.###-##'" ref="cpf")

                        v-checkbox(dense v-model="accept" class="checkboxFont" :rules="[v => !!v || 'Você precisa aceitar para continuar!']")
                          template(v-slot:label)
                            div Concordo com os&nbsp;
                              a(@click.stop @click="openTerm('use-terms')") Termos de uso
                              | ,&nbsp;
                              a(@click.stop @click="openTerm('analysis-policy')") Política de análise
                              | ,&nbsp;
                              a(@click.stop @click="openTerm('privacy-policy')") Política de privacidade
                              | &nbsp;e&nbsp;
                              a(@click.stop @click="openTerm('cancellation-policy')") Política de cancelamento
                    v-card-actions
                      v-btn(@click="register" color="buttons" class="white--text" block depressed rounded :disabled="accept==false") Começar

</template>
<script>
import {mask} from 'vue-the-mask'
import * as types from '@/store/mutation-types'
import { store } from '@/store'

  export default {
    name: 'Home',
    directives: {mask},
    data: () => ({
      accept: false,
      valid: true,
      cpf: ''
    }),
    methods: {

      openTerm(link) {
        let href = '/'+link
        window.open(href, '_blank');
      },

      register() {

        if(this.$refs.form.validate()) {

          store.commit(types.CPF, this.cpf.trim().replace(/[^0-9]/g, ''))
          this.$router.push('register')

        }
      },

      validateCPF(cpf){
          let sum
          let rest
          sum = 0
          if (cpf == "00000000000") return false
          for (let i=1; i<=9; i++) { sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i) }
          rest = (sum * 10) % 11
          if ((rest == 10) || (rest == 11))  { rest = 0 }
          if (rest != parseInt(cpf.substring(9, 10)) ) { return false }
          sum = 0;
          for (let i = 1; i <= 10; i++) { sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i) }
          rest = (sum * 10) % 11
          if ((rest == 10) || (rest == 11)) { rest = 0 }
          if (rest != parseInt(cpf.substring(10, 11) ) ) { return false }
          return true
      },

    },
    mounted() {
      this.$refs.cpf.focus()
      this.$emit('clicked')
    },
    computed: {
      validCPF () { // validação de CPF
        const validCPF = []
        const isValidCPF =
          v => this.validateCPF(v.trim().replace(/[^0-9]/g, '')) || `CPF inválido`

        validCPF.push(isValidCPF)
        return validCPF
      },
    }
  }
</script>
<style scoped>
  .main-container{
    height: 100%;
    background-color: #55CDC7 !important;
  }
  .checkboxFont .v-label{
    font-size: 12px !important;
  }
</style>
