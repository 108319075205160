<template lang="pug">
  v-container(fluid class="bg-terms")
    h1(class="primary--text") Política de análise
    p(class="accent--text") A iFraldas valida todas as informações antes de liberar seu empréstimo. Essa é uma medida que visa dar segurança à transação e evitar fraudes. Como correspondente bancário de diversas instituições financeiras, seguimos as diretrizes do Banco Central do Brasil, nos termos da Resolução nº. 3.954, de 24 de fevereiro de 2011.
    p(class="accent--text") O processo de solicitação acontece da seguinte forma:

    p(class="accent--text") Após escolher o valor desejado, será necessário completar o cadastro com seus dados pessoais. Depois dessa etapa, será obrigatório o envio de fatos e alguns documentos para a validação de segurança. São eles: - Identidade ou CNH aberta (Frente e Verso) - Cartão de Crédito com cvv tampado (Frente) - Cartão de crédito com cvv tampado (Verso) - Foto Selfie

    p(class="accent--text") Verifique se suas imagens estão com boa iluminação e com os dados legíveis. A iFraldas não coleta informações sensíveis, como o código de segurança do cartão.

    p(class="accent--text") Após o recebimento da sua solicitação completa, nossa equipe irá realizar o processo de verificação e análise de segurança. Caso exista alguma pendência, poderá ser solicitado o envio de documentação adicional, como fatura do cartão de crédito, vídeo manipulando o aplicativo do cartão ou do banco e comprovante de residência.
    p(class="accent--text") Caso sua solicitação seja aprovada pela equipe de análise, seu empréstimo será efetuado e você será notificado por e-mail ou whatsapp. Os valores serão depositados para sua conta corrente em até 24 horas após a aprovação.
    p(class="accent--text") Caso sua solicitação de empréstimo seja reprovada pela análise, você também será notificado em até 5 horas por e-mail ou whatsapp. Os principais motivos de reprovação são:
    p(class="accent--text")
      | a) falsidade ideológica;<br />
      | b) dados inconsistentes;<br />
      | c) fotos adulteradas;<br />
      | d) falsificação de cartões de crédito;<br />
      | e) usando cartão em nome de terceiros<br />
    p(class="accent--text") Caso exista alguma dúvida, você poderá entrar em contato com a Central de Atendimento via whatsapp pelo número (32) 99198-0290.
    p(class="accent--text") A Central de Atendimento funciona de segunda a sexta-feira, das 10h às 18h, exceto feriados.

</template>
<script>

  export default {
    data: () => ({

    })
  }

</script>
