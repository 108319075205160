<template lang="pug">
  v-container(fluid class="bg-terms")
    h1(class="primary--text") Política de privacidade
    h3(class="accent--text font-weight-bold") Olá! Seja bem-vindo à iFraldas, uma plataforma de intermediação de negócios entre pessoas físicas e instituições financeiras relativos a empréstimos através do cartão de crédito do Usuário.
    p(class="accent--text mt-4") Por favor, leia atentamente nossa política geral de privacidade (“ Política de Privacidade ”) que trata das informações pessoais dos usuários que são coletadas através do Site, além das modalidades de utilização dessas informações, como e em que situações poderão ser compartilhadas e quais os padrões de segurança adotados para a proteção das informações pessoais coletadas pela IFRALDAS SERVIÇOS LTDA, uma empresa estabelecida de acordo com as leis do Brasil e devidamente registrada no CNPJ sob nº 30.304.899/0001-05 (a “ Empresa ” ou “ iFraldas”). Todos os direitos reservados.
    p(class="accent--text") O simples acesso ao site www.ifraldas.com.br (o “ Site ”) implica na aceitação total e irrestrita de todos os termos e condições desta Política de Privacidade.
    p(class="accent--text") Caso você não concorde com quaisquer dispositivos dos Termos de Uso e da nossa Política de Privacidade, não utilize a iFraldas ou nossos serviços, pois a sua utilização representa sua aceitação integral e incondicional a esta Política de Privacidade
    p(class="accent--text") Esta Política de Privacidade da iFraldas faz parte integrante dos nossos Termos de Uso, os quais estão disponíveis em www.ifraldas.com.br.
    p(class="accent--text") A finalidade da coleta de dados da pessoa física é única e exclusivamente a obtenção o empréstimo.
    p(class="accent--text") A nossa Política de Privacidade esclarece como coletamos e tratamos seus dados individuais:
    p(class="accent--text") Qualquer informação fornecida pelos usuários, pessoa física, assim como o histórico de conversa pelo meio digital será coletada e guardada de acordo com os mais rígidos padrões de segurança e confiabilidade, respeitando a LGPD – Lei Geral de Proteção de Dados – Lei 13.709/2018 e o Marco Civil da Internet – Lei 12.965/2014, ficando claro desde logo que não serão coletados os chamados “dados sensíveis”;
    p(class="accent--text") Todas as informações coletadas dos Usuários trafegam de forma segura, utilizando processo de criptografia padrão da Internet;
    p(class="accent--text") As informações pessoais requeridas no processo de solicitação dos documentos são previstas em lei, em especial da Lei 9.613/98, que visam, para a segurança das partes, em especial do Usuário, a completa identificação e complementação cadastral;
    p(class="accent--text") As informações do Usuário, pessoa física, serão tratados no mais absoluto sigilo e confidencialidade, que somente será aberta em caso específico de determinação Judicial ou por Autoridade Competente, nos estritos termos da requisição formulada, e tão somente para a Autoridade requisitora.
    p(class="accent--text") Dados de geolocalização que não permitam identificar o cliente individualmente podem ser compartilhados com eventuais provedores desse serviço;
    p(class="accent--text") O acesso às informações coletadas está restrito a funcionários autorizados e empresas de apoio, para o uso adequado desses dados, que firmaram Termo de Confidencialidade, em caso remoto de uso indevido dos dados, estarão sujeitos às penas administrativas e legais, inclusive criminais aplicáveis ao caso concreto;
    p(class="accent--text") Manteremos a integridade das informações que nos forem fornecidas. A iFraldas compromete-se a utilizar as informações pessoais somente para o fim específico para o qual foram coletadas, incluindo enviar e-mails, mensagens e notificações sobre atividades, anúncios, informações promocionais, campanhas de marketing em geral, avisos relacionados a temas de interesse e outros comunicados, sejam eles próprios ou de terceiros.
    p(class="accent--text") Os dados pessoais que permitam a identificação dos clientes e que estejam sob a posse e controle da iFraldas são coletados, armazenados e utilizados para fins de processamento das transações realizadas por usuários, bem como conforme autorizado pelos Usuários de acordo com esta Política de Privacidade;
    p(class="accent--text") A fim de respeitar a legislação aplicável, a iFraldas poderá acessar e divulgar as Informações Pessoais dos Usuários, incluindo seus registros de acesso – como IP e horário de acesso – para cumprir ordens judiciais específicas ou requerimentos de autoridades governamentais competentes. Os servidores nos quais são armazenadas as Informações Pessoais são dos mais altos níveis de segurança.
    p(class="accent--text") Eventualmente, poderemos utilizar cookies (*) para confirmar sua identidade, personalizar seu acesso e acompanhar a utilização de nosso website visando o aprimoramento de sua navegação e funcionalidade; * Cookie: pequeno arquivo colocado em seu computador para rastrear movimentos dentro dos websites, como visitas a páginas e anúncios. Cookies não armazenam informações pessoais sem que você as tenha fornecido e não coletam informações registradas em seu computador.
    p(class="accent--text") A maioria dos browsers possibilita que o usuário, a qualquer instante, ative mecanismos para informá-lo quando os cookies estiverem acionados ou para evitar que sejam acionados, embora isso possa afetar a utilização de algumas funções de nosso site.
    p(class="accent--text") Para a melhor fruição dos serviços oferecidos pela iFraldas, é imprescindível que as Informações Pessoais sejam compartilhadas com empresas parceiras (como, por exemplo, bandeiras de cartão de crédito e bancos), responsáveis pelo processamento e análise dos dados coletados e pela entrega dos produtos ou serviços adquiridos. Ressaltamos que esses terceiros não estão autorizados a usar ou divulgar suas Informações Pessoais de qualquer outra maneira que não as previstas nesta Política de Privacidade. No entanto, ao utilizar os serviços ou adquirir produtos destes terceiros, você o fará a seu próprio risco, assim como aceitará que a presente Política de Privacidade não se aplica.
    p(class="accent--text") Fica proibido que qualquer Usuário utilize qualquer conteúdo do Site para o envio de Spam, a qualquer título, a quem quer que seja, responsabilizando-se perante a iFraldas e aos terceiros prejudicados pela prática desses atos.
    p(class="accent--text") A iFraldas não se responsabiliza por quaisquer comunicações eletrônicas fraudulentas que coletem dados pessoais dos usuários (phishing).
    p(class="accent--text") É de inteira responsabilidade do Usuário manter o ambiente de seu computador seguro, com o uso de ferramentas disponíveis como antivírus e firewall, entre outras, de modo a prevenir riscos eletrônicos e, ainda, utilizar-se de softwares atualizados e eficientes para o acesso ao Site.
    p(class="accent--text") O Usuário poderá, a qualquer momento, acessar, retificar ou excluir suas informações pessoais, através do Site.
    p(class="accent--text") A iFraldas reserva-se o direito de alterar os termos desta Política de Privacidade, informando previamente os Usuários, sempre com observância da legislação aplicável.
    p(class="accent--text") Quaisquer dúvidas podem ser enviadas para: contato@ifraldas.com.br.
</template>
<script>
  export default {
    data: () => ({

    })
  }
</script>
