<template src="./Register07.pug" lang="pug"></template>
<script>

import {mask} from 'vue-the-mask'

import * as types from '@/store/mutation-types'
import { store } from '@/store'

import { mapGetters } from 'vuex'

  export default {
    directives: {mask},
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      genero: ['masculino', 'feminino'],
      valid: true,
      name: '',
      userCpf: '',
      email: '',
      birthday: '',
      motherName: '',
      cellphone: '',
      gender: '',
      rules: {
        required: value => !!value || 'Campo obrigatório',
        email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Digite um e-mail válido',
        twoWords: value => (!!value && (/(\w.+\s).+/).test(value)) || 'Digite o nome completo',
        cellNumber: value => (value && value.length == 15) || 'Informe todos os dígitos'
      },

      emailRules: [
        v => !!v || 'Campo obrigatório',
        v => /.+@.+\..+/.test(v) || 'Digite um e-mail válido',
      ],
    }),
    methods: {
      maskCpf(v){
        v=v.replace(/\D/g,"")                    //Remove tudo o que não é dígito
        v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
        v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                             //de novo (para o segundo bloco de números)
        v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
        return v
      },
      validateCPF(cpf){
          let sum
          let rest
          sum = 0
          if (cpf == "00000000000") return false
          for (let i=1; i<=9; i++) { sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i) }
          rest = (sum * 10) % 11
          if ((rest == 10) || (rest == 11))  { rest = 0 }
          if (rest != parseInt(cpf.substring(9, 10)) ) { return false }
          sum = 0;
          for (let i = 1; i <= 10; i++) { sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i) }
          rest = (sum * 10) % 11
          if ((rest == 10) || (rest == 11)) { rest = 0 }
          if (rest != parseInt(cpf.substring(10, 11) ) ) { return false }
          return true
      },
      goNext() {

        if(this.$refs.form.validate()) {

          const [day, month, year] = this.birthday.split('/')

          console.log('niver: ', `${month}/${day}/${year}`)

          store.commit(types.NAME, this.name)
          store.commit(types.CPF, this.userCpf.trim().replace(/[^0-9]/g, ''))
          store.commit(types.EMAIL, this.email)
          store.commit(types.BIRTHDAY, `${year}-${month}-${day}`)
          store.commit(types.MOTHER_NAME, this.motherName)
          store.commit(types.CELLPHONE, this.cellphone.trim().replace(/[^0-9]/g, ''))
          store.commit(types.GENDER, this.gender)
          this.$router.push('register08')
        }
      }
    },
    computed: {
      ...mapGetters(['cpf']),

      validCPF () { // validação de CPF
        const validCPF = []
        const isValidCPF =
          v => this.validateCPF(v.trim().replace(/[^0-9]/g, '')) || `CPF inválido`

        validCPF.push(isValidCPF)
        return validCPF
      },
    },
    created() {
      this.userCpf = this.cpf
    }
  }
</script>
