<template src="./Register10.pug" lang="pug"></template>
<script>

import {mask} from 'vue-the-mask'
import { mapGetters } from 'vuex'

import * as types from '@/store/mutation-types'
import { store } from '@/store'

  export default {
    directives: {mask},
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      aceite: false,
      valid: true,
      transferType: "1",
      remountLayout: 0,

      pixType: {tipo:'celular', val:'celular'},
      pixTypeItems: [{tipo:'celular', val:'celular'}, {tipo:'CPF', val:'cpf'}, {tipo:'e-mail', val:'email'}, {tipo:'aleatória', val:'aleatoria'}],
      pixKey: "",
      pixKeyCel: '',
      pixKeyEmail: '',
      pixKeyRandon: '',

      phone: '',
      userCpf: '',

      agencia: '',
      conta: '',
      digito: '',
      tipoConta: {tipo:'Conta corrente', val:'corrente'},
      tipoContaItems: [{tipo:'Conta corrente', val:'corrente'}, {tipo:'Conta poupança', val:'poupanca'}],
      bankCod: '',
      bankList: [],
      rules: {
        required: value => !!value || 'Campo obrigatório',
        email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Digite um e-mail válido',
        twoWords: value => (!!value && (/(\w.+\s).+/).test(value)) || 'Digite o nome completo'
      },
    }),
    methods: {

      maskCpf(v){
        v=v.replace(/\D/g,"")                    //Remove tudo o que não é dígito
        v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
        v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                             //de novo (para o segundo bloco de números)
        v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
        return v
      },

      customFilter (item, queryText) {
        // const textOne = item.banco.toLowerCase()
        const textOne = item.banco.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        const textTwo = item.cod.toLowerCase()
        const searchText = queryText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      },

      goNext() {

        if(this.$refs.form.validate()) {

          if(this.transferType == "2"){ // se for pix
            var type = ''
            if(this.pixType.val) { type = this.pixType.val}else{ type = this.pixType }

            switch (type) {
              case "celular":
                this.pixKey = this.pixKeyCel.trim().replace(/[^0-9]/g, '')
                break;
              case "cpf":
                this.pixKey = this.userCpf.trim().replace(/[^0-9]/g, '')
                break;
              case "email":
                this.pixKey = this.pixKeyEmail
                break;
              case "aleatoia":
                this.pixKey = this.pixKeyRandon
                break;
              default:
                break
            }

            store.commit( types.ACCOUNT_TYPE, 'pix' )
            store.commit( types.PIX_KEY_TYPE, type )
            store.commit( types.PIX_KEY, this.pixKey )
            this.tipoConta = 'pix'
          }else {

            if(this.tipoConta.val == 'corrente'){
              this.tipoConta = 'corrente'
            }
            if(this.tipoConta.val == 'poupanca'){
              this.tipoConta = 'poupanca'
            }
            store.commit( types.ACCOUNT_TYPE, this.tipoConta )
            store.commit( types.BANK, this.bankCod )
            store.commit( types.BANK_NAME, this.banks.find(x => x.cod === this.bankCod).banco.split(' - ')[1])
            store.commit( types.AGENCY, this.agencia )
            store.commit( types.ACCOUNT, this.conta )
            if(this.digito != '') { store.commit( types.ACCOUNT_DIGIT, this.digito ) }
          }

          this.$router.push('register11')
        }
      }

    },
    computed: {
      ...mapGetters(['cpf', 'cellphone']),
      banks() {
        return require('../common/banks.js')
      }
    },
    created() {
      this.userCpf = this.cpf
      this.pixKeyCel = this.cellphone
    }
  }
</script>
