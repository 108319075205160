<template src="./Register12.pug" lang="pug"></template>
<script>

import { mapGetters, mapActions } from 'vuex'

import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3' // recaptcha Pagamento no crédito
Vue.use(VueReCaptcha, { siteKey: '6LfaK4IaAAAAAFyL_mC2cTeLgkcdpCOUVJn8QkOr' })

  export default {
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      aceite: false,
      captchaToken: '',
    }),
    computed: { ...mapGetters([ 'cardHash', 'userId', 'selectedAmount', 'selectedInstallments', 'selectedInstallmentsFactor' ]) },
    methods: {
      ...mapActions(['payment']),
      formatPrice(value) {
        if(value == undefined){ value = 0 }
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      async recaptcha() {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()
        this.captchaToken = await this.$recaptcha('ecommerce')
        this.pay()
      },

      async pay() {
        var cardPayload = {
          _id: this.userId,
          card_hash: this.cardHash,
          // token: this.captchaToken
        }

        await this.payment(cardPayload)
        this.$router.push('uploadid')
      }

    }
  }
</script>
