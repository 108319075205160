<template src="./Register09.pug" lang="pug"></template>
<script>

import {mask} from 'vue-the-mask'

import * as types from '@/store/mutation-types'
import { store } from '@/store'

  export default {
    directives: {mask},
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      valid: true,
      addressZipCode: '',
      addressStreet: '',
      addressNeighborhood: '',
      addressCity: '',
      addressState: '',
      addressNumber: '',
      addressComplement: '',
      stateList: [
        {nome: 'Acre', sigla: 'AC'},
        {nome: 'Alagoas', sigla: 'AL'},
        {nome: 'Amapá', sigla: 'AP'},
        {nome: 'Amazonas', sigla: 'AM'},
        {nome: 'Bahia', sigla: 'BA'},
        {nome: 'Ceará', sigla: 'CE'},
        {nome: 'Espírito Santo', sigla: 'ES'},
        {nome: 'Goiás', sigla: 'GO'},
        {nome: 'Maranhão', sigla: 'MA'},
        {nome: 'Mato Grosso', sigla: 'MT'},
        {nome: 'Mato Grosso do Sul', sigla: 'MS'},
        {nome: 'Minas Gerais', sigla: 'MG'},
        {nome: 'Pará', sigla: 'PA'},
        {nome: 'Paraíba', sigla: 'PB'},
        {nome: 'Paraná', sigla: 'PR'},
        {nome: 'Pernambuco', sigla: 'PE'},
        {nome: 'Piauí', sigla: 'PI'},
        {nome: 'Rio de Janeiro', sigla: 'RJ'},
        {nome: 'Rio Grande do Norte', sigla: 'RN'},
        {nome: 'Rio Grande do Sul', sigla: 'RS'},
        {nome: 'Rondônia', sigla: 'RO'},
        {nome: 'Roraima', sigla: 'RR'},
        {nome: 'Santa Catarina', sigla: 'SC'},
        {nome: 'São Paulo', sigla: 'SP'},
        {nome: 'Sergipe', sigla: 'SE'},
        {nome: 'Tocantins', sigla: 'TO'},
        {nome: 'Distrito Federal', sigla: 'DF'}
      ],

      rules: {
        required: value => !!value || 'Campo obrigatório',
      },
    }),
    methods: {

      fill(){
        this.addressStreet = '...'
        this.addressNeighborhood = '...'
        this.addressCity = '...'
        this.addressState = '...'

        this.addressZipCode = this.addressZipCode.trim().replace(/[^0-9]/g, '')
        var self = this

        var request = new XMLHttpRequest();
        request.open('get', "https://api.pagar.me/1/zipcodes/"+this.addressZipCode, true)
        request.send()

        request.onload = function () {
          var data = JSON.parse(this.response)
          if(!data.erro) {
            self.addressStreet = data.street
            self.addressNeighborhood = data.neighborhood
            self.addressCity = data.city
            self.addressState = data.state
            self.$refs.numero.focus()
          }else{
            console.log('CEP não encontrado')
            this.cleanForm()
          }
        }
      },
      cleanForm() {
        //Limpa valores do formulário de cep.
        this.addressStreet = ''
        this.addressNumber = ''
        this.addressComplement = ''
        this.addressNeighborhood = ''
        this.addressCity = ''
        this.addressState = ''
      },

      goNext() {

        if(this.$refs.form.validate()) {

          store.commit( types.CEP, this.addressZipCode.trim().replace(/[^0-9]/g, '') )
          store.commit( types.STATE, this.addressState )
          store.commit( types.STREET, this.addressStreet )
          store.commit( types.NEIGHBORHOOD, this.addressNeighborhood )
          store.commit( types.CITY,this.addressCity )
          store.commit( types.NUMBER, this.addressNumber )
          if(this.addressComplement != '') { store.commit(types.COMPLEMENT,this.addressComplement ) }

          this.$router.push('register10')
        }
      }

    },
    computed: { }
  }
</script>
