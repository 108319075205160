<template src="./Register03.pug" lang="pug"></template>
<script>

import {VMoney} from 'v-money'
import * as types from '@/store/mutation-types'
import { store } from '@/store'

  export default {
    directives: {money: VMoney},
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      valid: true,
      moneyMask: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      },
      cardLimitLeft: ''
    }),
    methods: {

      onFocus(){
        this.moneyMask = this.money
      },

      validateVal(value){

        const max = this.$store.state.register.cardLimitTotal
        const min = 200

        let price = value
        if( typeof(value) === 'string' ){
          price = Number( (value.trim().replace(/[^0-9]/g, '')/100).toFixed(2) )
        }

        if( price < min ) return "O mínimo permitido é "+(min).toLocaleString("pt-BR", {style: "currency", currency: "BRL", minimumFractionDigits: 2})
        if( price > max ) return "O limite do cartão é "+(max).toLocaleString("pt-BR", {style: "currency", currency: "BRL", minimumFractionDigits: 2})
        return true
      },

      goNext() {
        if(this.$refs.form.validate()) {
          store.commit(types.CARD_LIMIT_LEFT, Number( (this.cardLimitLeft.trim().replace(/[^0-9]/g, '')/100).toFixed(2) ))
          this.$router.push('register04')
        }
      }
    }
  }
</script>
