<template src="./Register02.pug" lang="pug"></template>
<script>

  import {VMoney} from 'v-money'
  import * as types from '@/store/mutation-types'
  import { store } from '@/store'

  export default {
    directives: {money: VMoney},
    data: () => ({
      valid: true,
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      moneyMask: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      },
      cardLimitTotal: ''
    }),
    methods: {

      onFocus(){
        this.moneyMask = this.money
      },
      validateMin(value){

        let price = value
        if( typeof(value) === 'string' ){
          price = Number( (value.trim().replace(/[^0-9]/g, '')/100).toFixed(2) )
        }

        if( price < 500 ) return "O mínimo permitido é R$ 500,00"
        if( price > 20000 ) return "O máximo permitido é R$ 20.000,00"
        return true
      },

      goNext() {
        if(this.$refs.form.validate()) {
          store.commit(types.CARD_LIMIT_TOTAL, Number( this.cardLimitTotal.trim().replace(/[^0-9]/g, '')/100 ).toFixed(2))
          this.$router.push('register03')
        }
      }
    }
  }
</script>
