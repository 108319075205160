<template lang="pug">
  v-container(class="main-container")
    v-row(justify="center")
      v-col(cols="12" sm="12" md="12" lg="12" xl="10")

        v-card(color="white")
          v-row(align="center" class="pt-8 pb-8" justify="center")
            v-img(alt="iFraldas" contain :src="`${imgPath}/site/imgs/mascote.png`" transition="scale-transition" max-width="200")
            v-col(cols="12" sm="12" md="5" lg="5" xl="5" class="text-center")
              p(class="subtitle accent--text ml-4 font-weight-regular") Este serviço não está disponível para o seu usuário. Por favor entre em contato com o nosso&nbsp;
                a(target="_blank" href="https://api.whatsapp.com/send?phone=3284044384&text=Ol%C3%A1!%20Como%20fa%C3%A7o%20para%20ter%20acesso%20ao%20iFraldas%20Cr%C3%A9dito?") suporte
              v-btn(:to="{ name: 'logout'}" color="buttons" class="white--text" depressed rounded) Sair

</template>
<script>

  export default {
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET
    }),
  }
</script>
<style scoped>
  #img-logo {
    background-color: #cce;
    border-radius: 50%;
  }
  .main-container{
    background-color: #55CDC7 !important;
  }
  .checkboxFont .v-label{
    font-size: 12px !important;
  }
</style>
