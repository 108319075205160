<template src="./UploadId.pug" lang="pug"></template>
<script>

  import { mapActions, mapGetters } from 'vuex'

  export default {
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      isSelecting: false
    }),
    computed: {
      ...mapGetters(['uploadPercent', 'documentUploaded', 'userId'])
    },
    methods: {
      ...mapActions(['getUploadUrl', 'newUploadFile', 'updateDocs']),

      onButtonClick() {
        this.isSelecting = true
        window.addEventListener('focus', () => {
          this.isSelecting = false
        }, { once: true })

        this.$refs.picture.click()
      },

      async savePhoto(e){

        const urlFile = URL.createObjectURL(e.target.files[0])
        const blob = await fetch(urlFile).then(r => r.blob())

        const imgFile = new File([blob], "temp.jpg")
        const filename = 'id.jpg'

        const url = await this.getUploadUrl({
          op: 'PUT',
          cdn: process.env.VUE_APP_S3_BUCKET,
          bucket: 'credito/'+this.userId,
          filename: filename
        })

        await this.newUploadFile({
          op: 'PUT',
          url: url,
          formData: imgFile
        })

        if(this.documentUploaded){ this.updateInfo(filename) }

      },
      async updateInfo(file) {
        await this.updateDocs({
          _id: this.userId,
          docs: {
            type: "identidade",
            file: "credito/"+this.userId+"/"+file
          }
        })

        this.$router.push({ name: 'upload-card' })

      }
    }
  }
</script>
