<template src="./Register04.pug" lang="pug"></template>
<script>

  import { mapActions, mapGetters } from 'vuex'
  import * as types from '@/store/mutation-types'
  import { store } from '@/store'

  export default {
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      valid: true,
      rules: {
        required: value => !!value || 'Campo obrigatório'
      },
      options: ['Montar o enxoval do bebê', 'Saúde', 'Estudos', 'Viagem', 'Compras', 'Quitar dívida pessoal', 'Outros'],
      need: ''
    }),
    computed: {
      ...mapGetters(['cpf', 'cardLimitTotal', 'cardLimitLeft', 'user'])
    },
    methods: {
      ...mapActions(['postCpf']),

      async goNext() {
        if(this.$refs.form.validate()) {
          store.commit(types.NEED, this.need)
          store.commit(types.CARD_LIMIT_LEFT, this.cardLimitLeft)

          const user = JSON.parse(localStorage.getItem('user'))

          await this.postCpf({
            cpf: this.cpf,
            user: user.id,
            cardLimitTotal: this.cardLimitTotal,
            cardLimitLeft: this.cardLimitLeft,
            reason: this.need
          })

          this.$router.push('register05')
        }
      }
    }
  }
</script>
