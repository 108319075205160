<template lang="pug">
  v-container(fluid class="bg-terms")
    h1(class="primary--text") Política de cancelamento

    h2(class="accent--text") Posso cancelar meu cadastro?
    p(class="accent--text") É possível, a qualquer momento, solicitar o cancelamento do seu cadastro mediante solicitação enviada para o canal informado abaixo. Uma vez solicitado o cancelamento, o cadastro do usuário será definitivamente excluído dentro de 3 dias úteis. Será preciso ficar de olho na sua caixa de entrada, pois será enviado uma confirmação de cancelamento de conta via e-mail.

    h2(class="accent--text") Posso cancelar minha solicitação?
    p(class="accent--text") Sim. Todos os pedidos poderão ser cancelados em até 7 dias corridos, depois do pagamento. Os finais de semana e feriados também contam nesse prazo. Esse é um direito seu que está no artigo 49 do Código de Defesa do Consumidor Lei 8078/90.

    h2(class="accent--text") Se eu cancelar minha solicitação, o meu limite volta quando?
    p(class="accent--text") O cancelamento da pré-autorização é feito de acordo com prazo que o emissor de seu cartão efetuar a liberação. Basta checar com o banco emissor do seu cartão de crédito.

    h2(class="accent--text") Como funciona o estorno do valor no meu cartão de crédito?
    p(class="accent--text") O estorno funciona de duas maneiras. Após realizarmos o estorno em nosso sistema, os bancos levam entre uma e três semanas para reconhecer a devolução, podendo ocorrer na próxima fatura.
    p(class="accent--text") O crédito retorna para a gerenciadora do cartão. Ela pode abater o valor estornado do total de sua fatura e manter o lançamento das cobranças ou cancelar todas as cobranças futuras e devolver o crédito para o limite do cartão.
    p(class="accent--text") A forma como o estorno é administrado é prerrogativa da gerenciadora de seu cartão e está previsto no contrato assinado entre o banco emissor e o portador do cartão.

    h2(class="accent--text") Como realizar o cancelamento da minha compra, solicitação ou conta?
    p(class="accent--text") Você precisará entrar em contato com a Central de Atendimento via whatsapp pelo número (32) 99198-0290.

    p(class="accent--text") A Central de Atendimento funciona de segunda a sexta-feira, das 10h às 18h, exceto feriados.
</template>
<script>

  export default {
    data: () => ({

    })
  }
</script>
