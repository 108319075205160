<template src="./Finish.pug" lang="pug"></template>
<script>

  import { mapGetters } from 'vuex'

  export default {
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET
    }),
    computed: {
      ...mapGetters(['transactionId'])
    }
  }
</script>
